@import '../assets/common_vars'

#video-youtube
    iframe
        border-radius: 12px !important
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    max-width: 1300px
    margin: 0 auto
    margin-bottom: 57px
    margin-top: 57px
    width: 70%
    @media ( max-width: 1500px )
        width: 80%
    @media ( max-width: 900px )
        width: 90%
    @media ( max-width: 600px )
        width: 95%
