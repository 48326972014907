@import '../assets/common_vars'

#video-commingsoon
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    max-width: 1300px
    margin: 80px auto
    width: 40%
    @media ( max-width: 900px )
        width: 90%
    @media ( max-width: 600px )
        width: 95%
        padding: 20px 30px 40px 30px
    background-color: #FFFFFF
    padding: 70px 50px 100px 50px
    border-radius: 12px
    font-family: TelefonicaLight
    text-align: center
    h2
        font-family: TelefonicaMedium
        color: $blue
        font-size: 25px
        font-weight: unset !important
    a
        color: $blue
        word-wrap: anywhere
        text-decoration: none
