$bg: #F2F4FF
$blue: #06f
$w: #fff
$border: #7070701a

$mw: 85%

@font-face
    font-family: 'TelefonicaLight'
    src:    url('../assets/fonts/telefonica/Light/Telefonica_Sans_Light.woff')
    src:    url('fonts/telefonica/Light/Telefonica_Sans_Light.woff2') format('woff2')
    font-weight: normal
    font-style: normal
@font-face
    font-family: 'TelefonicaMedium'
    src:    url('../assets/fonts/telefonica/Medium/Telefonica_Sans_Medium.woff')
    src:    url('fonts/telefonica/Medium/Telefonica_Sans_Medium.woff2') format('woff2')
    font-weight: normal
    font-style: normal