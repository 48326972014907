
@import '../assets/common_vars'

footer
    background-color: #0066ff
    .content
        margin: 0 auto
        max-width: $mw
        padding-top: 54px
        padding-bottom: 24px

        .rrss
            margin-top: 25px
            display: flex
            align-items: center
            gap: 20px

        .legal-links
            margin-top: 63px
            display: flex
            a, .configure-cookies
                cursor: pointer
                font-size: 12px
                color: $w
                text-decoration: none
                margin-right: 40px
                cursor: pointer
                position: relative
                padding-bottom: 3px
                &:hover:before
                    visibility: visible
                    width: 100%
                &:before 
                    content: ""
                    position: absolute
                    width: 0
                    height: 1px
                    bottom: 0
                    left: 0
                    background-color: #fff
                    visibility: hidden
                    transition: all .3s ease-in-out

            @media(max-width: 620px)
                margin-top: 33px
                display: flex
                flex-direction: column
                
                a
                    margin-top: 11px
                    &:hover:before
                        visibility: hidden
