@import '../assets/common_vars'


header
    height: 77px
    border-bottom: 0.5px solid $border

    .content
        margin: 0 auto
        display: flex
        align-items: center
        justify-content: space-between
        max-width: $mw
        height: 100%
        > svg 
            width: 136px

.languages-container
    display: flex
    align-items: center

    .contact
        cursor: pointer
        padding-right: 15px
        i
            padding-top: 6px
            color: #06f
    .language
        display: flex
        margin-right: 10px
        gap: 10px
    a
        text-decoration: none
        cursor: default

    svg
        cursor: pointer
        .elipse
            stroke: #06f
        text
            fill: #06f

    a circle
        stroke-dasharray: 80
        stroke-dashoffset: 80
        stroke-linecap: round
        transition: all 1s ease

    a:hover circle, a.active circle
        stroke-dashoffset: 0

    .language:hover .active:not(:hover) circle
        stroke-dashoffset: 80

    .first, .second
        height: 26px

    #Elipse_169
        stroke: #06f

.logoutBtt
    align-self: center
    display: flex
    align-items: center
    cursor: pointer
    width: fit-content
    margin-left: 20px
    svg
        transition: .2 ease-in-out
        transform-origin: center center
        & > path
        fill: $blue
    &:hover
        svg
            transform: scale(1.2)