@import 'assets/common_vars'
    
html, body
    background-color: $bg
    margin: 0
    padding: 0
    font-family: 'TelefonicaLight'

main
    min-height: calc(80vh)
    display: flex
    flex-direction: column
    justify-content: center
    .content
        background: url('./assets/bg.png')
        background-size: auto 90%
        background-repeat: no-repeat
        background-position: center center
        display: flex
        justify-content: center

.logo-container
    display: flex
    align-items: center
    justify-content: center
    img.logo-title
        width: 80%
        max-width: 350px
        margin-top: 25px
        margin-bottom: 25px

[data-amplify-authenticator]
    font-family: 'TelefonicaLight'
    min-height: 620px !important


[data-amplify-container]
    margin: 60px 0
    transition: 2s all

[data-amplify-authenticator] [data-amplify-router]
    box-shadow: none !important
    border: 0 !important
    border-radius: 12px
input, .amplify-field__show-password
    background-color: $bg !important
    border: 0 !important
input, button
    border-radius: 0px !important

.amplify-tabs
    border: none !important
.amplify-tabs-item
    font-family: 'TelefonicaMedium' !important
    border: none !important
    margin-top: 0 !important
    font-weight: unset !important
    &:first-child
        border-radius: 12px 0 0 0 !important
    &:last-child
        border-radius: 0 12px 0 0 !important
    &:hover
        color: $blue !important
.amplify-tabs-item[data-state=active]
    color: $blue !important
.amplify-tabs-item[data-state=inactive]
    background-color: $bg !important
.amplify-button--link
    color: $blue !important
    &:hover
        background-color: transparent !important

.amplify-button--loading, .amplify-button--loading:active, .amplify-button--loading:focus, .amplify-button--loading:hover
    color: #FFFFFF !important

.amplify-label
    font-family: TelefonicaMedium !important
    color: #58617a !important
.amplify-button--primary
    background-color: $blue !important
    border-radius: 25px !important
    margin: auto !important
    margin-top: 30px !important

.amplify-icon svg path
    fill: $blue

input[name="username"], input[name="email"]
    text-transform: lowercase !important